<template>
    <div class="page">
        <img src="../assets/image/chinaBG.png" class="chinaBG">
        <dv-loading class="pageLoading" v-if="loading">Loading...</dv-loading>
        <div class="header">
            <img class="bj" src="../assets/image/header.png">
            <div class="container">
                <div class="left">
                    <div class="des">
                        <div class="t">设备分布区域</div>
                        <div class="t1">Regional Distribution Of Devices</div>
                    </div>
                    <div class="v">{{stat_01.region_count}}<sub>+</sub></div>
                </div>
                <div class="middle">
                    <div class="t" @click="$router.back()" style="cursor: pointer;">巨烨健康管理云平台</div>
                    <div class="t1">ZAYATA HEALTH MANAGEMENT SAAS PLATFORM</div>
                </div>
                <div class="right">
                    <div class="v">{{stat_01.refresh_interval}}<sub>min</sub></div>
                    <div class="des">
                        <div class="t">实时数据颗粒度</div>
                        <div class="t1">Real-Time Data Granularity</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <!-- <dv-border-box-1 style="width:100%;height:100%;position: relative;"> -->
            <div id="chart" ref="chart"></div>
            <div class="tags">
                <div class="tag">
                    <img src="../assets/image/label.png">
                    <div class="container">
                        <div class="t">中国</div>
                        <div class="t1">China</div>
                    </div>
                </div>
            </div>
            <!-- </dv-border-box-1> -->
        </div>
        <div class="footer">
            <img class="bj" src="../assets/image/footer.png">
            <div class="container">
                <div class="item">
                    <div class="v">{{stat_03.partner_count}}</div>
                    <div class="des">
                        <div class="t">生态伙伴</div>
                        <div class="t1">Trade Partner</div>
                    </div>
                </div>
                <div class="item">
                    <div class="v" style="color: #FFB822;">{{stat_03.users_count}}</div>
                    <div class="des">
                        <div class="t">活跃用户</div>
                        <div class="t1" style="color: #FFB822;">Active Users</div>
                    </div>
                </div>
                <div class="item">
                    <div class="v" style="color: #D74CFF;">{{stat_03.report_count}}</div>
                    <div class="des">
                        <div class="t">日上报数据量</div>
                        <div class="t1" style="color: #D74CFF;">Report Data Volume Per Day</div>
                    </div>
                </div>
                <div class="item">
                    <div class="v" style="color: #ECE156;">{{stat_03.devices_count}}</div>
                    <div class="des">
                        <div class="t">激活设备总数</div>
                        <div class="t1" style="color: #ECE156;">Active Devices Amount</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import worldData from "./json/china.json";
    echarts.registerMap("china", worldData);
    import axios from '@/axios/index'

    export default {
        data() {
            return {
                loading: true,
                stat_01: {},
                stat_02: [],
                stat_03: {},
                visualMapMax: 0
            }
        },
        watch: {
            '$store.state.onresizeNumber': {
                handler() {
                    if (this.chart) this.chart.resize();
                }
            }
        },
        async mounted() {
            await this.getData()
            this.loading = false
        },


        methods: {
            async getData() {
                let response = await axios.request({
                    url: "/api/dataworks.super/super_display",
                    method: "GET",
                    params: {
                        name: 'china'
                    }
                })
                let { stat_01 = {}, stat_02 = [], stat_03 = {}, refresh = 300 } = response.data.data
                if (this.refreshTimer) clearInterval(this.refreshTimer)
                this.refreshTimer = setInterval(this.getData, refresh * 1000);
                let visualMapMax = 0
                stat_02.forEach(item => {
                    item.value = 0
                    item.gps = [parseFloat(item.gps[0]), parseFloat(item.gps[1])]
                    item.data.forEach(item1 => {
                        item1.gps = [parseFloat(item1.gps[0]), parseFloat(item1.gps[1])]
                        item1.value = parseFloat(item1.value)
                        item.value += parseFloat(item1.value)
                        if (item1.value > visualMapMax) visualMapMax = item1.value
                    })
                })
                this.stat_01 = stat_01
                this.stat_02 = stat_02
                this.stat_03 = stat_03
                this.visualMapMax = visualMapMax
                this.setEcharts()
            },
            setEcharts() {
                let planePath = 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z';
                let color = ['#FFFFFF', '#67C23A', '#409EFF', '#E6A23C', '#F56C6C', '#909399', '#FFFFFF', '#67C23A', '#409EFF', '#E6A23C', '#F56C6C', '#909399', '#FFFFFF', '#67C23A', '#409EFF', '#E6A23C', '#F56C6C', '#909399', '#FFFFFF', '#67C23A', '#409EFF', '#E6A23C', '#F56C6C', '#909399']; //颜色集合
                let rldData = [] // 热力点数据
                let endData = [] //到达点数据
                let stat_02 = this.stat_02
                stat_02.forEach(item => {
                    item.data.forEach(item1 => {
                        rldData.push({
                            name: item1.city,
                            value: [item1.gps[0], item1.gps[1], item1.value]
                        })
                        endData.push({
                            name: item1.city,
                            value: item1.gps
                        })
                    })
                })

                let series = [
                    { //热力点
                        zlevel: 999,
                        type: 'heatmap',
                        coordinateSystem: 'geo',
                        data: rldData,
                    },
                    {
                        zlevel: 99,
                        type: 'scatter', //层级比地图区高 用来触摸显示自定义tooltip
                        coordinateSystem: 'geo', //
                        symbolSize: 50,
                        itemStyle: {
                            opacity: 0,
                        },
                        data: rldData,
                        label: {
                            show: true,
                            color: "#FFFFFF",
                            opacity: 1,
                            offset: [0, -20],
                            formatter: function(params) {
                                return `{name|${params.data.name}}{value|${params.data.value[params.data.value.length-1]}}`
                            },
                            rich: {
                                name: {
                                    backgroundColor: 'rgba(194, 22, 244, 0.6)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                },
                                value: {
                                    backgroundColor: 'rgba(194, 22, 244,0.3)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                }
                            }
                        }
                },
                    { // 结束点
                        visualMap: false,
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        zlevel: 1,
                        rippleEffect: {
                            brushType: 'stroke'
                        },
                        symbolSize: 5,
                        itemStyle: {
                            normal: {
                                opacity: 1,
                                color: '#FFFFFF'
                            }
                        },
                        data: endData
                }
            ];
                // 动态加路径  实现每个组颜色不同
                for (let i = 0; i < stat_02.length; i++) {
                    let item = stat_02[i];
                    let luJingData = [] //路径数据
                    let itemValue = 0 //一共多少
                    for (let ii = 0; ii < item.data.length; ii++) {
                        let item1 = item.data[ii];
                        luJingData.push({
                            fromName: item.city,
                            toName: item1.city,
                            coords: [item.gps, item1.gps],
                            value: item1.value
                        })
                        itemValue += item1.value
                    }

                    series.push({ //线 路径
                        type: 'lines',
                        zlevel: 1,
                        symbol: ['none', 'none'], //两端标记
                        // effect: {
                        //     show: true,
                        //     period: 5,
                        //     trailLength: 0,
                        //     symbol: planePath,
                        //     symbolSize: 15
                        // },
                        lineStyle: {
                            normal: {
                                color: color[i],
                                width: 1,
                                opacity: 1,
                                curveness: 0.1 //边的曲度，支持从 0 到 1 的值，值越大曲度越大。
                            }
                        },
                        data: luJingData
                    })

                    series.push({ //线 动画
                        type: 'lines',
                        zlevel: 2,
                        symbol: ['none', 'none'], //两端标记
                        effect: {
                            show: true,
                            period: 10, //特效动画的时间，单位为 s。
                            trailLength: 0.7, //特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长。
                            color: color[i],
                            symbolSize: 3 //特效标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示高和宽，例如 [20, 10] 表示标记宽为20，高为10。
                        },
                        lineStyle: {
                            normal: {
                                width: 0,
                                curveness: 0.1 //边的曲度，支持从 0 到 1 的值，值越大曲度越大。
                            }
                        },
                        data: luJingData
                    })

                    series.push({ //起始点
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        zlevel: 9,
                        rippleEffect: {
                            brushType: 'stroke' //波纹的绘制方式，可选 'stroke' 和 'fill'
                        },
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: color[i]
                            }
                        },
                        data: [{
                            name: item.city,
                            value: [].concat(item.gps).concat([itemValue])
                    }],
                        tooltip: {
                            borderColor: "",
                            borderWidth: 0,
                            backgroundColor: "",
                            padding: 0,
                            formatter: function(params, ticket, callback) {
                                if (params.seriesType == "effectScatter") {
                                    return `<div style="border: 1px solid #C216F4;height:32px;text-align: center;display:flex;font-size: 16px;color:#FFFFFF;font-weight: 600;width:auto">
                                            <div style="background: rgba(194, 22, 244, 0.4);line-height: 32px;padding:0 17px;border-right: 1px solid #C216F4;">${params.name}</div>
                                            <div style="line-height: 32px;padding:0 17px;background: rgba(194, 22, 244, 0.3);">${params.value[params.value.length-1]}</div>
                                        </div>`
                                }
                            }
                        }
                    })

                }
                this.chart = echarts.init(this.$refs.chart);
                this.chart.setOption({
                    visualMap: {
                        show: false,
                        orient: "horizontal",
                        zlevel: 999,
                        type: "continuous", //类型为分段型
                        seriesIndex: 0, //指定取哪个系列的数据，即哪个系列的  默认取所有系列 不指定会覆盖所有颜色
                        min: 0,
                        max: this.visualMapMax,
                        splitNumber: 5, //分5段
                        inRange: {
                            color: ['rgba(49, 255, 233, 1)', 'rgba(49, 255, 233, 0.8)', 'rgba(49, 255, 233, 0.6)', 'rgba(49, 255, 233, 0.4)', 'rgba(49, 255, 233, 0.2)'] //每段的颜色
                        }
                    },
                    tooltip: {
                        show: true
                    },
                    grid: {
                        width: '100%',
                        height: '100%',
                        // left: '0%',
                        // right: '0%',
                        // bottom: '0%',
                        // top:'10%',
                        containLabel: true
                    },
                    // layoutCenter: ['70%', '50%'], //位置
                    // layoutSize: '65%', //大小
                    geo: {
                        top: '30%',
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params, ticket, callback) { return }
                        },
                        name: '世界地图',
                        type: 'map',
                        map: 'china',
                        label: {
                            emphasis: {
                                show: true,
                                color: '#fff'
                            }
                        },
                        zoom: 1.7,
                        // roam: true, //支持拖拽缩放
                        // scaleLimit: { //滚轮缩放的极限控制
                        //     min: 0.5, //缩放最小大小
                        //     max: 6, //缩放最大大小
                        // },
                        itemStyle: {
                            normal: {
                                borderWidth: 1, //边际线大小
                                // borderColor: 'red', //边界线颜色
                                areaColor: '#3d6fd9', //默认区域颜色
                                borderColor: '#719dfa'
                            },
                            emphasis: {
                                show: true,
                                // areaColor: '#2a333d'
                                areaColor: '#3066ba', //鼠标滑过区域颜色
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#fff'
                                    }
                                }
                            }
                        }
                    },
                    series
                })
            },
        },
        destroyed() {
            if (this.refreshTimer) clearInterval(this.refreshTimer)
            if (this.chart) this.chart.dispose()
        }
    }
</script>
<style lang="less" scoped>
    .pageLoading {
        background-color: #1330b8;
        color: #FFFFFF;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    }

    .tags {
        position: absolute;
        right: .4rem;
        top: .16rem;

        .tag {
            font-size: 16px;
            position: relative;

            img {
                width: 1.5rem;
                vertical-align: top;
            }

            .container {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .t {
                    font-size: .28rem;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                .t1 {
                    font-size: .14rem;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }

        }
    }


    .page {
        background-color: #1330b8;
        display: flex;
        flex-direction: column;
        position: relative;

        .chinaBG {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    #chart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .header {
        width: 100%;
        position: relative;

        .bj {
            width: 100%;
            vertical-align: top;
        }

        .container {
            // border: 1px solid red;
            box-sizing: border-box;
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;

            .left {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;

                .des {
                    width: 2.12rem;
                    text-align: right;

                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .2rem;
                        font-size: .14rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }

                .v {
                    margin-left: .16rem;
                    font-size: .65rem;
                    font-weight: 800;

                    sub {
                        position: relative;
                        bottom: .1rem;
                    }
                }
            }

            .middle {
                flex: auto;
                // border: 1px solid red;
                box-sizing: border-box;
                position: relative;

                .t {
                    position: absolute;
                    left: 1.75rem;
                    top: .1rem;
                    font-size: .6rem;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                .t1 {
                    position: absolute;
                    bottom: .1rem;
                    font-size: .14rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    left: 1.89rem;
                    letter-spacing: 0.05rem;

                }
            }

            .right {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;

                .des {
                    width: 1.8rem;
                    text-align: left;

                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .2rem;
                        font-size: .14rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }

                .v {
                    margin-right: .16rem;
                    font-size: .65rem;
                    font-weight: 800;


                    sub {
                        position: relative;
                        bottom: .14rem;
                    }
                }


            }


        }
    }

    .body {
        flex: auto;
        position: relative;
        overflow: hidden;
    }

    .footer {
        width: 100%;
        box-sizing: border-box;
        position: relative;

        .bj {
            width: 100%;
            vertical-align: top;
        }

        .container {
            box-sizing: border-box;
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            padding-bottom: .55rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .item {
                display: flex;
                align-items: center;
                margin-left: 1.01rem;

                &:first-of-type {
                    margin-left: 0;
                }

                .v {
                    font-size: .5rem;
                    font-weight: 800;
                    color: #1DC9B7;
                }

                .des {
                    margin-left: .09rem;

                    .t {
                        font-size: .2rem;
                        font-weight: 400;
                        color: #FFFFFF;
                    }

                    .t1 {
                        margin-top: .01rem;
                        font-size: .14rem;
                        font-weight: 400;
                        color: #1DC9B7;
                        opacity: 0.8;
                    }
                }

            }

        }
    }
</style>